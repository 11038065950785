import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'

THREE.ColorManagement.enabled = false

/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Axes Helper

//const axesHelper = new THREE.AxesHelper()
//scene.add(axesHelper)

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const texture = textureLoader.load('/textures/matcaps/8.png')

/**
 * Fonts
 */
const fontLoader = new FontLoader()
const material = new THREE.MeshMatcapMaterial({
    matcap: texture
})

fontLoader.load('/fonts/helvetiker_regular.typeface.json', (font) => {
    const bevelSize = 0.02
    const bevelThickness = 0.03
    const textParams = {
        font,
        size: 0.5,
        height: 0.2,
        curveSegments: 5,
        bevelEnabled: true,
        bevelThickness,
        bevelSize,
        bevelOffset: 0,
        bevelSegments: 4
    }
    const logoGeometry = new TextGeometry('Livepixelz', textParams)
    const baselineTextParams = {...textParams, size: 0.168}
    const baselineGeometry = new TextGeometry("Stay tuned, we'll kick it soon !", baselineTextParams)
    logoGeometry.center()
    baselineGeometry.center()
    baselineGeometry.translate(0, -0.5,0)

    
    const logo = new THREE.Mesh(
        logoGeometry, material
    )
    const baseline = new THREE.Mesh(
        baselineGeometry, material
    )

    scene.add(logo, baseline)

    camera.lookAt(logo)
})

/**
 * Donuts
 */

console.time('donuts')
const donutGeometry = new THREE.TorusGeometry(.3, 0.2, 20, 45)

for (let i = 0; i < 200; i++) {
    
    const donut = new THREE.Mesh(donutGeometry, material)

    donut.position.x = (Math.random() - .5) * 10
    donut.position.y = (Math.random() - .5) * 10
    donut.position.z = (Math.random() - .5) * 10

    const scale = Math.random()
    donut.scale.set(scale, scale, scale)

    donut.rotation.x = (Math.random() - .5) * Math.PI * 2
    donut.rotation.y = (Math.random() - .5) * Math.PI * 2
    donut.rotation.z = (Math.random() - .5) * Math.PI * 2

    scene.add(donut)
}

console.timeEnd('donuts')

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2

scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.maxDistance = 5

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()